import React from "react";

const SearchIcon = (props: any) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.29264 1.78784C3.98683 1.78784 1.29395 4.48072 1.29395 7.78655C1.29395 11.0924 3.98683 13.7905 7.29264 13.7905C8.70466 13.7905 10.0033 13.2956 11.0296 12.474L13.5283 14.9714C13.6544 15.0923 13.8228 15.159 13.9975 15.1572C14.1722 15.1554 14.3392 15.0853 14.4628 14.9619C14.5864 14.8385 14.6567 14.6716 14.6588 14.4969C14.6608 14.3223 14.5943 14.1537 14.4736 14.0275L11.9749 11.5288C12.7972 10.5009 13.2926 9.20019 13.2926 7.78655C13.2926 4.48072 10.5985 1.78784 7.29264 1.78784ZM7.29264 3.1212C9.87787 3.1212 11.958 5.20133 11.958 7.78655C11.958 10.3718 9.87787 12.4571 7.29264 12.4571C4.70742 12.4571 2.62728 10.3718 2.62728 7.78655C2.62728 5.20133 4.70742 3.1212 7.29264 3.1212Z"
        fill="#989898"
      />
    </svg>
  );
};

export default SearchIcon;
