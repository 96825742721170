import React from "react";

const NextIcon = (props: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.8099 7.53176L6.50758 2.19171C6.38495 2.0681 6.22124 2 6.04668 2C5.87212 2 5.70841 2.0681 5.58577 2.19171L5.19529 2.58487C4.9412 2.84107 4.9412 3.25745 5.19529 3.51326L9.64781 7.99751L5.19035 12.4867C5.06771 12.6104 5 12.7751 5 12.9508C5 13.1267 5.06771 13.2915 5.19035 13.4152L5.58083 13.8083C5.70356 13.9319 5.86718 14 6.04174 14C6.2163 14 6.38001 13.9319 6.50264 13.8083L11.8099 8.46336C11.9329 8.33936 12.0004 8.1738 12 7.99781C12.0004 7.82112 11.9329 7.65566 11.8099 7.53176Z"
        fill="#939393"
      />
    </svg>
  );
};

export default NextIcon;
