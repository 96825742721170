import React from "react";

const UserIcon = (props: any) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.364 12.636C15.3837 11.6558 14.217 10.9301 12.9439 10.4909C14.3074 9.55179 15.2031 7.9802 15.2031 6.20312C15.2031 3.33413 12.869 1 10 1C7.131 1 4.79688 3.33413 4.79688 6.20312C4.79688 7.9802 5.69262 9.55179 7.05609 10.4909C5.78308 10.9301 4.61631 11.6558 3.63605 12.636C2.10283 14.1693 1.1909 16.1583 1.02683 18.2975C0.997136 18.6847 1.3148 19 1.70312 19C2.09145 19 2.40283 18.6842 2.43847 18.2975C2.79409 14.4383 6.04961 11.4062 10 11.4062C13.9504 11.4062 17.2059 14.4383 17.5615 18.2975C17.5972 18.6842 17.9086 19 18.2969 19C18.6852 19 19.0029 18.6847 18.9732 18.2975C18.8091 16.1583 17.8972 14.1693 16.364 12.636ZM10 10C7.90641 10 6.20312 8.29675 6.20312 6.20312C6.20312 4.1095 7.90641 2.40625 10 2.40625C12.0936 2.40625 13.7969 4.1095 13.7969 6.20312C13.7969 8.29675 12.0936 10 10 10Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};

export default UserIcon;
