import React from "react";
import { NavbarBottom, NavbarTop } from "../components";

const Navbar = () => {
  return (
    <div className="container">
      <NavbarTop />
      <NavbarBottom />
    </div>
  );
};

export default Navbar;
