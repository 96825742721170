import { IErrorMessages } from "types/errors";

export const ERROR_MESSAGES: IErrorMessages = {
  0: "TOAST_MESSAGES.0",
  10: "TOAST_MESSAGES.10",
  200: "TOAST_MESSAGES.200",
  400: "TOAST_MESSAGES.400",
  700: "TOAST_MESSAGES.700",
  701: "TOAST_MESSAGES.701",
  702: "TOAST_MESSAGES.702",
  703: "TOAST_MESSAGES.703",
  704: "TOAST_MESSAGES.704",
  705: "TOAST_MESSAGES.705",
  706: "TOAST_MESSAGES.706",
  14002: "TOAST_MESSAGES.14002",
  14003: "TOAST_MESSAGES.14003",
  15000: "TOAST_MESSAGES.15000",
  15001: "TOAST_MESSAGES.15001",
  15002: "TOAST_MESSAGES.15002",
  16000: "TOAST_MESSAGES.16000",
  16001: "TOAST_MESSAGES.16001",
  17000: "TOAST_MESSAGES.17000",
  17001: "TOAST_MESSAGES.17001",
  19000: "TOAST_MESSAGES.19000",
  19001: "TOAST_MESSAGES.19001",
  20000: "TOAST_MESSAGES.20000",
  20001: "TOAST_MESSAGES.20001",
  21000: "TOAST_MESSAGES.21000",
  21001: "TOAST_MESSAGES.21001",
  22000: "TOAST_MESSAGES.22000",
  22001: "TOAST_MESSAGES.22001",
  22002: "TOAST_MESSAGES.22002",
  22003: "TOAST_MESSAGES.22003",
  22004: "TOAST_MESSAGES.22004",
  22005: "TOAST_MESSAGES.22005",
  22006: "TOAST_MESSAGES.22006",
  24000: "TOAST_MESSAGES.24000",
  24001: "TOAST_MESSAGES.24001",
  24002: "TOAST_MESSAGES.24002",
  25000: "TOAST_MESSAGES.25000",
  26000: "TOAST_MESSAGES.26000",
  70000: "TOAST_MESSAGES.70000",
  27000: "TOAST_MESSAGES.27000",
  27001: "TOAST_MESSAGES.27001",
  27002: "TOAST_MESSAGES.27002",
  28000: "TOAST_MESSAGES.28000",
  28001: "TOAST_MESSAGES.28001",
  29000: "TOAST_MESSAGES.29000",
  29001: "TOAST_MESSAGES.29001",
  30000: "TOAST_MESSAGES.30000",
  30001: "TOAST_MESSAGES.30001",
  40000: "TOAST_MESSAGES.40000",
  50000: "TOAST_MESSAGES.50000",
  50001: "TOAST_MESSAGES.50001",
  60000: "TOAST_MESSAGES.60000",
  60001: "TOAST_MESSAGES.60001",
  60002: "TOAST_MESSAGES.60002",
};
