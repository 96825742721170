import React from "react";

const PreviosIcon = (props: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.19006 7.53176L9.49242 2.19171C9.61505 2.0681 9.77876 2 9.95332 2C10.1279 2 10.2916 2.0681 10.4142 2.19171L10.8047 2.58487C11.0588 2.84107 11.0588 3.25745 10.8047 3.51326L6.35219 7.99751L10.8097 12.4867C10.9323 12.6104 11 12.7751 11 12.9508C11 13.1267 10.9323 13.2915 10.8097 13.4152L10.4192 13.8083C10.2964 13.9319 10.1328 14 9.95826 14C9.7837 14 9.61999 13.9319 9.49736 13.8083L4.19006 8.46336C4.06713 8.33936 3.99961 8.1738 4 7.99781C3.99961 7.82112 4.06713 7.65566 4.19006 7.53176Z"
        fill="#939393"
      />
    </svg>
  );
};

export default PreviosIcon;
