import { URLSearchParamsInit, useSearchParams } from "react-router-dom";

export function useCustomSearchParams(): [
  ob: ISearchParams,
  fn: ISetSearchParams,
] {
  const [search, setSearch] = useSearchParams();

  let obj: ISearchParams = {};

  for (let [key, value] of Object(search.entries())) {
    if (obj[key]) {
      if (Array.isArray(obj[key])) obj[key] = [...obj[key], value];
      else obj[key] = [obj[key], value];
    } else obj[key] = value;
  }

  return [obj, setSearch as ISetSearchParams];
}

export type ISetSearchParams = (
  nextInit: URLSearchParamsInit,
  navigateOptions?:
    | {
        replace?: boolean | undefined;
        state?: any;
      }
    | undefined,
) => void;

export interface ISearchParams {
  [key: string]: string | string[];
}
