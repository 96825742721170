import React from "react";

const BagIcon = (props: any) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.9999 15.3041C13.0054 15.3053 12.0412 14.9617 11.2715 14.3318C10.5018 13.702 9.97422 12.8248 9.77862 11.8497C9.76303 11.7469 9.76996 11.6421 9.79893 11.5423C9.82789 11.4425 9.87821 11.3502 9.94638 11.2718C10.0146 11.1934 10.099 11.1308 10.1937 11.0883C10.2885 11.0457 10.3914 11.0243 10.4953 11.0255C10.6661 11.023 10.8321 11.0815 10.9635 11.1906C11.0949 11.2997 11.183 11.452 11.212 11.6203C11.347 12.2646 11.6995 12.8427 12.2105 13.2577C12.7214 13.6727 13.3596 13.8992 14.0178 13.8992C14.6761 13.8992 15.3143 13.6727 15.8252 13.2577C16.3361 12.8427 16.6887 12.2646 16.8237 11.6203C16.8527 11.452 16.9408 11.2997 17.0722 11.1906C17.2036 11.0815 17.3696 11.023 17.5404 11.0255C17.6443 11.0243 17.7472 11.0457 17.842 11.0883C17.9367 11.1308 18.0211 11.1934 18.0893 11.2718C18.1575 11.3502 18.2078 11.4425 18.2368 11.5423C18.2657 11.6421 18.2727 11.7469 18.2571 11.8497C18.0603 12.8309 17.5275 13.7127 16.7504 14.3433C15.9733 14.9738 15.0006 15.3136 13.9999 15.3041Z"
        fill="white"
      />
      <path
        d="M18.3005 8.80435H16.8671V7.55014C16.8671 6.78983 16.5651 6.06066 16.0274 5.52304C15.4898 4.98541 14.7607 4.68338 14.0003 4.68338C13.24 4.68338 12.5109 4.98541 11.9732 5.52304C11.4356 6.06066 11.1336 6.78983 11.1336 7.55014V8.80435H9.7002V7.55014C9.7002 6.40967 10.1532 5.31592 10.9597 4.50948C11.7661 3.70305 12.8599 3.25 14.0003 3.25C15.1408 3.25 16.2346 3.70305 17.041 4.50948C17.8474 5.31592 18.3005 6.40967 18.3005 7.55014V8.80435Z"
        fill="white"
      />
      <path
        d="M20.3786 24.7503H7.62151C7.32988 24.7507 7.04123 24.6917 6.7731 24.577C6.50498 24.4623 6.263 24.2942 6.06187 24.083C5.86075 23.8719 5.70469 23.622 5.60319 23.3486C5.50168 23.0752 5.45686 22.784 5.47144 22.4927L6.05196 10.137C6.07598 9.58305 6.31307 9.05981 6.71372 8.67653C7.11436 8.29325 7.64758 8.07956 8.20203 8.08008H19.7981C20.3525 8.07956 20.8858 8.29325 21.2864 8.67653C21.687 9.05981 21.9241 9.58305 21.9482 10.137L22.5287 22.4927C22.5433 22.784 22.4984 23.0752 22.3969 23.3486C22.2954 23.622 22.1394 23.8719 21.9382 24.083C21.7371 24.2942 21.4951 24.4623 21.227 24.577C20.9589 24.6917 20.6702 24.7507 20.3786 24.7503ZM8.20203 9.52063C8.01195 9.52063 7.82966 9.59614 7.69525 9.73054C7.56085 9.86495 7.48534 10.0472 7.48534 10.2373L6.90482 22.5644C6.89996 22.6615 6.9149 22.7585 6.94874 22.8497C6.98257 22.9408 7.03459 23.0241 7.10163 23.0945C7.16867 23.1649 7.24933 23.2209 7.33871 23.2591C7.42808 23.2974 7.5243 23.317 7.62151 23.3169H20.3786C20.4758 23.317 20.572 23.2974 20.6614 23.2591C20.7508 23.2209 20.8314 23.1649 20.8985 23.0945C20.9655 23.0241 21.0175 22.9408 21.0514 22.8497C21.0852 22.7585 21.1002 22.6615 21.0953 22.5644L20.5148 10.2087C20.5148 10.0186 20.4393 9.83628 20.3049 9.70187C20.1705 9.56747 19.9882 9.49196 19.7981 9.49196L8.20203 9.52063Z"
        fill="white"
      />
    </svg>
  );
};

export default BagIcon;
