import React from "react";

const CancelIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.1988 16.5197L27.5439 7.17424C28.1521 6.56636 28.1521 5.5835 27.5439 4.97562C26.936 4.36774 25.9532 4.36774 25.3453 4.97562L15.9999 14.321L6.65474 4.97562C6.04658 4.36774 5.064 4.36774 4.45612 4.97562C3.84796 5.5835 3.84796 6.56636 4.45612 7.17424L13.8012 16.5197L4.45612 25.8651C3.84796 26.4729 3.84796 27.4558 4.45612 28.0637C4.75907 28.3669 5.15739 28.5192 5.55543 28.5192C5.95347 28.5192 6.35151 28.3669 6.65474 28.0637L15.9999 18.7183L25.3453 28.0637C25.6485 28.3669 26.0465 28.5192 26.4446 28.5192C26.8426 28.5192 27.2407 28.3669 27.5439 28.0637C28.1521 27.4558 28.1521 26.4729 27.5439 25.8651L18.1988 16.5197Z"
        fill="#107FE4"
      />
    </svg>
  );
};

export default CancelIcon;
