import React from "react";

const PhoneIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00846 10.6946C5.00846 10.0759 5.01591 9.45726 5.02339 8.83575L5.02339 8.83574C5.02719 8.52065 5.03099 8.20482 5.03384 7.88788C5.03384 4.48258 7.16556 1.99976 10.1094 1.99976H13.8906C16.8344 1.99976 18.9662 4.48258 18.9662 7.88788C18.9915 9.25196 19 10.616 19 11.9899C19 13.3638 18.9915 14.7475 18.9662 16.1116C18.9662 19.5169 16.8344 21.9998 13.8906 21.9998H10.1094C7.16556 21.9998 5.03384 19.5169 5.03384 16.1018C5.01692 14.8162 5 13.5012 5 12.1666L5.00846 10.6946ZM13.2807 19.6398V19.7998C13.2807 20.4734 12.7175 20.9998 12.0007 20.9998C11.301 20.9998 10.7207 20.4734 10.7207 19.7998V19.6398C10.7207 18.9838 11.301 18.4398 12.0007 18.4398C12.7175 18.4398 13.2807 18.9838 13.2807 19.6398ZM7.01671 8.44316C7.01136 8.84714 7.00604 9.24926 7.00604 9.65137L7 10.6082C7 11.4757 7.01208 12.3305 7.02417 13.1661C7.02417 15.3859 8.54683 16.9998 10.6495 16.9998H13.3505C15.4532 16.9998 16.9758 15.3859 16.9758 13.1725C16.994 12.2858 17 11.3864 17 10.4934C17 9.60034 16.994 8.71369 16.9758 7.82704C16.9758 5.61359 15.4532 3.99976 13.3505 3.99976H10.6495C8.54683 3.99976 7.02417 5.61359 7.02417 7.82704C7.02214 8.03305 7.01942 8.23834 7.01671 8.44316Z"
        fill="white"
      />
    </svg>
  );
};

export default PhoneIcon;
